#circle-container {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;
  canvas {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  #circle {
    text-align: center;
  }

  #circle .value {
    display: inline-block;
    color: white;
    position: relative;
    top: -60px;
    line-height: 0px;
    font-size: 36px;
    font-weight: bold;
  }

  #chip-container {
    display: flex;
    justify-content: center;
    .chip {
      color: black;
      background-color: white;
    }
  }
}
