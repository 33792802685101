// Code ganked from:
// https://codepen.io/furnace/pen/PGygEd

$breadcrumb-background: lighten($primary-color, 40%);
$breadcrumb-text-color: rgb(255, 255, 255);
$size: 22px;

.breadcrumbs {
  box-shadow: none;
  background-color: $breadcrumb-background;
  height: 44px;
  line-height: 44px;
  padding: 0 20px;

  .breadcrumb {
    font-size: 14px;
    color: darken($breadcrumb-text-color, 10%);
    display: inline-block;
    padding-right: 1rem;
    padding-left: calc(#{$size} + 1rem);

    &:hover {
      transition: background ease 0.3s;
      &:after {
        transition: border-left-color ease 0.3s, background ease 0.3s;
      }
    }

    &:before {
      display: none;
    }

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      border: transparent $size solid;
      border-left-color: rgba(0, 0, 0, 1);
      margin-left: 1rem;
    }

    &:nth-child(odd) {
      background: darken($breadcrumb-background, 2%);
      &:after {
        border-left-color: darken($breadcrumb-background, 2%);
      }

      &:hover {
        background: lighten($breadcrumb-background, 2%);
        &:after {
          border-left-color: lighten($breadcrumb-background, 2%);
        }
      }

      &:last-child {
        &:after {
          border-color: darken($breadcrumb-background, 2%);
        }
      }
    }

    &:nth-child(even) {
      background: lighten($breadcrumb-background, 2%);
      &:after {
        border-left-color: lighten($breadcrumb-background, 2%);
      }

      &:hover {
        background: lighten($breadcrumb-background, 4%);
        &:after {
          border-left-color: lighten($breadcrumb-background, 4%);
        }
      }

      &:last-child {
        &:after {
          border-color: lighten($breadcrumb-background, 2%);
        }
      }
    }

    &:last-child {
      color: $breadcrumb-text-color;
      &:after {
        border-left-width: 0;
      }
    }
  }
}
