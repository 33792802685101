//@import url('https://fonts.googleapis.com/css?family=Roboto');

html {
  height: 100%;
  background-color: #F6F8FA;
}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

body, #application-root, #app, .portal, .app-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

#application-root {
  background-color: #F6F8FA;
}
