html {
  height: 100%;
  max-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#root {
  height: inherit;
}

.react-app {
  height: inherit;
}

.page-content {
  overflow-y: auto;
}