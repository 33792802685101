.radio-select .select-area {
  margin-top: 12px;
}

.radio-select .radio-select-label {
  margin-right: 40px;
}

.radio-select .radio-select-label span {
  padding-left: 28px;
}
