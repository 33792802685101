$pagination-item-height: 60px;

.pagination-container {
  width: 100%;

  .pagination {
    li {
      height: $pagination-item-height;
      a {
        line-height: $pagination-item-height;
        padding: 0 22px;

        i {
          padding-top: 13px;
        }
      }
    }
  }
}

