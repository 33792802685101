nav {
  form.search-input {
    background-color: lighten($primary-color, 10%);
    height: 45px !important;
    margin-top: 10px;
    border-radius: 5px;

    input[id="search"] {
      height: 45px !important;
      margin-top: 10px;
      border-radius: 5px;
    }

    i.search-icon {
      height: 30px;
      line-height: 30px;
    }

    .active {
      i.search-icon {
        display: none;
      }
    }

    i.close-icon {
      height: 45px;
      line-height: 45px;
    }
  }

  .trial-notification {
    background-color: red;
    padding: 10px 50px;
  }

  .header-nav-items {
    .header-nav-item {
      //width: 200px;
    }
  }

  ul.dropdown-content {
    li {
      //height: 64px;
    }
  }
}
