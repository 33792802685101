//@import '~react-data-grid/lib/styles.css';

.grid .rdg-light {
  background-color: #fff;
  color: inherit;
  padding: 0;
  outline: 1px solid #e7eaec;
  clear: both;
}

.grid .rdg-light .rdg-header-row .rdg-cell {
  padding: 8px !important;
  font-weight: 700;
}

.grid .rdg-cell {
  padding: 8px;
  font-weight: 700;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.grid .rdg-editor-container {
  background-color: #eeeebb;
  text-align: center;
  padding: 0 8px 0 8px;
}

.rdg .rdg-row .rdg-cell.rdg-editor-container {
  padding:0; overflow: visible;
  contain: size style;
}
