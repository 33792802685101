.sidebar-wrapper {
  background-color: color("green", "lighten-2");
  height: 100vh;
}

$completion-section-height: 204px;

#circle-container {
  height: $completion-section-height;
  width: 300px;
  position: fixed;
  left: 0px;
  background-color: color("red", "lighten-1");
  padding: 20px;
}

.sidenav {
  //margin-top: $completion-section-height;
  //padding-bottom: $completion-section-height;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: $completion-section-height;

  li {
    a {
      display: flex;
      justify-content: left; /* align horizontal */
      align-items: center; /* align vertical */
      line-height: 14px;
      font-size: 12px;
      padding: 30px !important;
    }

    .category {
      height: 42px;

      .category-name {
        height: 42px;
        width: 210px;
        line-height: 14px;
        font-size: 12px;
        text-align: center;
        margin-left: 4px;
        display: flex;
        justify-content: center; /* align horizontal */
        align-items: center; /* align vertical */
      }
    }
  }
}

