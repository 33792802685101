// Note:  The font sizes in this component scale based on viewport size
// https://www.geeksforgeeks.org/font-scaling-based-on-width-of-container-using-css/

.answer-option {
  .card-title {
    font-size: 1.5vw;
    text-decoration: underline;
  }

  // Default answer input
  .description {
    font-size: 1.0vw;
  }

  // Custom answer input
  textarea {
    font-size: 1.0vw;
  }
}

.custom-answer-option .input-field .form-error {
  color: #ea526f;
  background-color: transparent;
}
