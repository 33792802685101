form {
  label {
    .label-text {
      display: inline;
      padding: 0px 5px 3px 5px;
      margin: 0px 0px 0px 5px;
    }
  }

  .form-error {
    display: inline;
    padding: 0px 5px 0px 5px;
    background-color: lighten($secondary-color, 20%);
  }

  .single-file-upload {
    label {
      color: #2B5F83;
      text-transform: uppercase;
    }
    button {
      float: right;
    }
    img {
      // default settings actually do a good job of auto-scaling
    }
  }
}

#update-system-form {
  textarea.materialize-textarea {
    min-height: 100px;
  }
}

.action-item {
  textarea.materialize-textarea {
    min-height: 100px;
  }
}

.user-note {
  button {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
  }
}
