.tabs {
  border-radius: 10px;
  border: 1px solid $tabs-underline-color;
  margin-top: 10px;

    .tab {
      display: inline-block;
      text-align: center;
      line-height: 48px;
      height: 48px;
      padding: 0;
      margin: 0;
      text-transform: uppercase;

      a {
        &:focus,
        &:focus.active {
          background-color: transparentize($tabs-underline-color, .8);
          outline: none;
        }

        &:hover,
        &.active {
          background-color: transparent;
          color: $tabs-text-color;
        }

        color: rgba($tabs-text-color, .7);
        display: block;
        width: 100%;
        height: 100%;
        padding: 0 24px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        transition: color .28s ease, background-color .28s ease;
      }

      &.disabled a,
      &.disabled a:hover {
        color: rgba($tabs-text-color, .4);
        cursor: default;
      }
    }
    .indicator {
      position: absolute;
      bottom: 0;
      height: 2px;
      background-color: $tabs-underline-color;
      will-change: left, right;
    }

}

.user-tabs {
  .tab {
    line-height: 48px;
    height: 48px;

    a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 0 24px;
      font-size: 12px;
    }
  }
}


//.tabs {
//  &.tabs-transparent {
//    background-color: transparent;
//
//    .tab a,
//    .tab.disabled a,
//    .tab.disabled a:hover {
//      color: rgba(255,255,255,0.7);
//    }
//
//    .tab a:hover,
//    .tab a.active {
//      color: #fff;
//    }
//
//    .indicator {
//      background-color: #fff;
//    }
//  }
//
//  //&.tabs-fixed-width {
//  //  display: flex;
//  //
//  //  .tab {
//  //    flex-grow: 1;
//  //  }
//  //}
//
//  position: relative;
//  overflow-x: auto;
//  overflow-y: hidden;
//  height: 48px;
//  width: 100%;
//  //background-color: $tabs-bg-color;
//  margin: 0 auto;
//  white-space: nowrap;
//
//  .tab {
//    display: inline-block;
//    text-align: center;
//    line-height: 48px;
//    height: 48px;
//    padding: 0;
//    margin: 0;
//    text-transform: uppercase;
//
//    a {
//      &:focus,
//      &:focus.active {
//        background-color: transparentize($tabs-underline-color, .8);
//        outline: none;
//      }
//
//      &:hover,
//      &.active {
//        background-color: transparent;
//        color: $tabs-text-color;
//      }
//
//      color: rgba($tabs-text-color, .7);
//      display: block;
//      width: 100%;
//      height: 100%;
//      padding: 0 24px;
//      font-size: 14px;
//      text-overflow: ellipsis;
//      overflow: hidden;
//      transition: color .28s ease, background-color .28s ease;
//    }
//
//    &.disabled a,
//    &.disabled a:hover {
//      color: rgba($tabs-text-color, .4);
//      cursor: default;
//    }
//  }
//  .indicator {
//    position: absolute;
//    bottom: 0;
//    height: 2px;
//    background-color: $tabs-underline-color;
//    will-change: left, right;
//  }
//}
//
//// Fixed Sidenav hide on smaller
//@media #{$medium-and-down} {
//  .tabs {
//    display: flex;
//
//    .tab {
//      flex-grow: 1;
//
//      a {
//        padding: 0 12px;
//      }
//    }
//  }
//}
