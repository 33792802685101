#threats-table {
  .header {
    font-size: 14px;
    background-color: lighten($primary-color, 60%);
  }

  tr.unselected {
    .impact-unselected {
      background-color: lighten($secondary-color, 10%);
    }
  }
}
